<template>
  <v-dialog v-model="dialog.show" width="800">
    <v-card max-width="800" class="mx-auto">
      <v-toolbar color="teal" dark>
        <v-icon class="toolbar-title-icon">mdi-account</v-icon>
        <v-toolbar-title
        >{{
            `Gebruiker ${
              checkIRCLicenseStatus(user) === "false" ? " uitgeschakeld" : ""
            }`
          }}
        </v-toolbar-title>
      </v-toolbar>
      <v-sheet v-if="user">
        <v-container>
          <v-row>
            <v-col cols="6" class="pa-0">
              <UserDetailsListItem
                title="Voornaam"
                :user-value="user.first_name"
              />
            </v-col>
            <v-col cols="6" class="pa-0">
              <UserDetailsListItem
                title="Achternaam"
                :user-value="user.last_name"
              />
            </v-col>
            <v-col cols="6" class="pa-0">
              <UserDetailsListItem
                title="Emailadres"
                :user-value="user.email"
              />
            </v-col>
            <v-col cols="6" class="pa-0">
              <UserDetailsListItem
                title="Telefoonnummer"
                :user-value="user.phone"
              />
            </v-col>
            <v-col cols="6" class="pa-0">
              <UserDetailsListItem
                title="Mobiele nummer"
                :user-value="user.mobile"
              />
            </v-col>
            <v-col cols="6" class="pa-0">
              <UserDetailsListItem
                title="Afdeling"
                :user-value="user.department"
              />
            </v-col>
            <v-col cols="6" class="pa-0">
              <UserDetailsListItem
                title="Functie"
                :user-value="user.job_title"
              />
            </v-col>
            <v-col cols="6" class="pa-0">
              <UserDetailsListItem
                title="Activatiedatum"
                :user-value="user.created_at | formatDate"
              />
            </v-col>
            <v-col cols="6" class="pa-0" v-if="user.irc_logins_count">
              <UserDetailsListItem
                title="Aantal logins"
                :user-value="user.irc_logins_count"
              />
            </v-col>
            <v-col cols="6" class="pa-0" v-if="user.irc_logins">
              <UserDetailsListItem
                title="Laatste login"
                :user-value="user.irc_logins.created_at | formatDateTime"
              />
            </v-col>
          </v-row>

          <v-divider class="mb-5 mt-5" />

          <div class="license-grid">
            <irc-license-actions
              v-if="ircLicense"
              :irc-license="ircLicense"
              class="license-item"
              :user="user"
            />
            <otk-license-actions
              v-if="otkLicense"
              :otk-license="otkLicense"
              class="license-item"
              :user="user"
            />
            <dark-web-license-actions
              v-if="darkwebLicense"
              :darkweb-license="darkwebLicense"
              class="license-item"
            />
          </div>

          <v-divider class="mb-5 mt-5" />
          <v-btn
            v-if="asAdmin"
            color="primary"
            @click="metaDataDialog.show = true"
          >Meta
          </v-btn>
          <v-btn
            color="warning"
            @click="editUserDialog.show = true"
            class="ml-1"
          >Wijzigen
          </v-btn>
          <v-btn
            v-if="asAdmin"
            color="warning"
            @click="openUserPermissionsDialog"
            class="ml-1"
          >Permissies
          </v-btn>
          <v-btn
            color="error"
            @click="deleteUserDialog.show = true"
            :disabled="!canDelete"
            class="ml-1"
          >Verwijderen
          </v-btn>
          <v-btn color="error" class="ml-1" @click="resetUserDialog.show = true"
          >Account reset
          </v-btn>
          <v-btn color="error" class="ml-1" @click="reset2faDialog.show = true"
          >2FA Reset
          </v-btn>

          <v-btn
            v-if="checkIRCLicenseStatus(user) === 'true'"
            color="error"
            class="ml-1"
            @click="disableUserDialog.show = true"
          >Account uitschakelen
          </v-btn>
          <v-btn
            v-else
            color="primary"
            class="ml-1"
            @click="enableUserDialog.show = true"
            :disabled="checkIRCLicenseStatus(user) === ''"
          >Account inschakelen
          </v-btn>

          <v-btn @click="dialog.show = false" class="ml-1" color="error"
          >Annuleren
          </v-btn>
        </v-container>
      </v-sheet>
    </v-card>
    <v-dialog v-model="deleteUserDialog.show" width="600">
      <v-sheet>
        <v-container>
          <p>U staat op het punt een gebruiker te verwijderen.</p>
          <p>Weet u dit zeker?</p>
          <v-btn
            @click="deleteUser"
            :loading="deleteUserDialog.loading"
            color="error"
          >Verwijderen
          </v-btn>
        </v-container>
      </v-sheet>
    </v-dialog>

    <v-dialog v-model="resetUserDialog.show" width="600">
      <v-sheet>
        <v-container>
          <p>
            U staat op het punt een gebruiker te resetten. Dit heeft als gevolg
            dat tot dusver opgeslagen gegevens, zoals bijvoorbeeld logging,
            verloren gaan.
          </p>
          <p>Weet u dit zeker?</p>
          <v-btn
            @click="resetUser"
            :loading="resetUserDialog.loading"
            color="error"
          >Reset gebruiker
          </v-btn>
        </v-container>
      </v-sheet>
    </v-dialog>

    <v-dialog v-model="reset2faDialog.show" width="600">
      <v-sheet>
        <v-container>
          <p>
            U staat op het punt de 2fa van een gebruiker te resetten.
          </p>
          <p>Weet u dit zeker?</p>
          <v-btn
            @click="sendResetTwoFactorMail"
            :loading="reset2faDialog.loading"
            color="error"
          >Reset 2fa
          </v-btn>
        </v-container>
      </v-sheet>
    </v-dialog>

    <v-dialog v-model="disableUserDialog.show" width="600">
      <v-sheet>
        <v-container>
          <p>
            Gebruikersaccount uitschakelen.
          </p>
          <p>Weet u dit zeker?</p>
          <v-btn
            @click="disableUser"
            :loading="disableUserDialog.loading"
            color="error"
          >Gebruiker uitschakelen
          </v-btn>
        </v-container>
      </v-sheet>
    </v-dialog>

    <v-dialog v-model="enableUserDialog.show" width="600">
      <v-sheet>
        <v-container>
          <p>
            Gebruikersaccount inschakelen.
          </p>
          <p>Weet u dit zeker?</p>
          <v-btn
            @click="enableUser"
            :loading="enableUserDialog.loading"
            color="error"
          >Gebruiker inschakelen
          </v-btn>
        </v-container>
      </v-sheet>
    </v-dialog>

    <v-dialog v-model="editUserDialog.show" width="800">
      <v-card>
        <v-toolbar color="teal" dark>
          <v-icon class="toolbar-title-icon">mdi-account-edit</v-icon>
          <v-toolbar-title>Gebruikergegevens wijzigen</v-toolbar-title>
        </v-toolbar>
        <v-sheet>
          <UserDetailsForm
            v-if="user"
            :user="user"
            @cancel="cancel"
            @submit="editUser"
          />
        </v-sheet>
      </v-card>
    </v-dialog>

    <v-dialog v-model="metaDataDialog.show" width="800" v-if="user">
      <v-card>
        <v-toolbar color="teal" dark>
          <v-icon class="toolbar-title-icon">mdi-database-eye</v-icon>
          <v-toolbar-title>Meta data</v-toolbar-title>
        </v-toolbar>
        <v-sheet>
          <v-container>
            <v-row>
              <v-col cols="6" class="pa-0">
                <UserDetailsListItem
                  title="Username"
                  :user-value="getIRCUsername(user)"
                />
              </v-col>
              <v-col cols="6" class="pa-0">
                <UserDetailsListItem
                  title="Registratiedatum"
                  :user-value="user.created_at | formatDateTime"
                />
              </v-col>
              <v-col cols="6" class="pa-0">
                <UserDetailsListItem
                  title="Laatste gewijzigd op"
                  :user-value="user.updated_at | formatDateTime"
                />
              </v-col>
              <v-col cols="6" class="pa-0">
                <UserDetailsListItem
                  title="Bewerking vergrendeld"
                  :user-value="user.edit_locked === 0 ? 'Nee' : 'Ja'"
                />
              </v-col>
            </v-row>

            <v-divider class="mb-5 mt-5" />

            <div class="license-grid">
              <LicenseMeta v-if="this.ircLicense" :license="this.ircLicense" />
              <LicenseMeta v-if="this.otkLicense" :license="this.otkLicense" />
              <LicenseMeta
                v-if="this.darkwebLicense"
                :license="this.darkwebLicense"
              />
            </div>

            <v-divider class="mb-5 mt-5" />
            <v-btn
              @click="metaDataDialog.show = false"
              class="ml-3"
              color="error"
            >Annuleren
            </v-btn>
          </v-container>
        </v-sheet>
      </v-card>
    </v-dialog>

    <v-dialog v-model="userPermissionsDialog.show" width="600">
      <v-card>
        <v-toolbar color="teal" dark>
          <v-icon class="toolbar-title-icon">mdi-account-alert</v-icon>
          <v-toolbar-title>Gebruiker permissies</v-toolbar-title>
        </v-toolbar>
        <v-sheet class="pa-2">
          <v-form v-model="form.valid">
            <v-container class="px-0" fluid>
              <v-radio-group v-model="radioGroup">
                <v-radio value="user">
                  <template v-slot:label>
                    <div><strong>Gebruiker</strong></div>
                  </template>
                </v-radio>
                <v-radio value="customer">
                  <template v-slot:label>
                    <div><strong>Beheerder</strong></div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-container>
            <v-btn color="primary" @click="saveUserPermissions" class="ml-3"
            >Opslaan
            </v-btn>
          </v-form>
        </v-sheet>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import IRCLicenseActions from "@/components/Licenses/LicenseActions/IRCLicenseActions";
import OTKLicenseActions from "@/components/Licenses/LicenseActions/OTKLicenseActions";
import DarkWebLicenseActions from "@/components/Licenses/LicenseActions/DarkWebLicenseActions";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import UserDetailsListItem from "@/components/Licenses/LicenseManagement/UserDetailsListItem";
import UserDetailsForm from "@/components/users/UserDetailsForm";
import LicenseMeta from "@/components/Licenses/LicenseMeta";

export default {
  props: {
    user: Object,
    organisation: Object
  },
  name: "UserDetailsDialog",
  components: {
    LicenseMeta,
    UserDetailsForm,
    "irc-license-actions": IRCLicenseActions,
    "otk-license-actions": OTKLicenseActions,
    DarkWebLicenseActions,
    UserDetailsListItem
  },
  data() {
    return {
      radioGroup: "user",
      form: {
        valid: false
      },
      dialog: {
        show: false
      },
      deleteUserDialog: {
        show: false,
        loading: false
      },
      resetUserDialog: {
        show: false,
        loading: false
      },
      reset2faDialog: {
        show: false,
        loading: false
      },
      disableUserDialog: {
        show: false,
        loading: false
      },
      enableUserDialog: {
        show: false,
        loading: false
      },
      editUserDialog: {
        show: false,
        loading: false
      },
      userPermissionsDialog: {
        show: false,
        loading: false
      },
      metaDataDialog: {
        show: false,
        loading: false
      }
    };
  },
  methods: {
    openUserPermissionsDialog() {
      if (!this.user.permissions) {
        this.user.permissions = [];
      }

      const index = this.user.permissions.indexOf("customer");
      if (index > -1) {
        this.radioGroup = "customer";
      } else {
        this.radioGroup = "user";
      }

      this.userPermissionsDialog.show = true;
    },
    getIRCUsername(user) {
      if (user) {
        if (!user.licenses || !user.licenses[0] || !user.licenses[0].meta)
          return "";
        if (user.licenses[0].meta.username) {
          return `irc\\${user.licenses[0].meta.username}`;
        }
      }
      return "";
    },
    checkIRCLicenseStatus(user) {
      if (user) {
        if (!user.licenses || !user.licenses[0] || !user.licenses[0].meta)
          return "";
        if (user.licenses[0].meta.username) {
          return user.licenses[0].active ? "true" : "false";
        }
      }
      return "";
    },
    saveUserPermissions() {
      if (this.radioGroup === "user") {
        if (this.user.permissions === null) {
          this.user.permissions = [];
        }
        const index = this.user.permissions.indexOf("customer");
        if (index > -1) {
          this.user.permissions.splice(index, 1);
        }
      }

      if (this.radioGroup === "customer") {
        if (this.user.permissions === null) {
          this.user.permissions = [];
        }
        const index = this.user.permissions.indexOf("customer");
        if (index === -1) {
          this.user.permissions.push("customer");
        }
      }

      this.editUser();
      this.userPermissionsDialog.show = false;
    },
    cancel() {
      this.editUserDialog.show = false;
    },
    editUser() {
      this.editUserDialog.loading = true;
      RepositoryFactory.get("users")
        .update(this.user, this.user.id)
        .then(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.users.updated"
          );
        })
        .catch(reason => {
          console.log(reason);
        })
        .finally(() => {
          this.editUserDialog.show = false;
        });
    },
    deleteUser() {
      this.deleteUserDialog.loading = true;
      let user = this.user;
      RepositoryFactory.get("users")
        .delete(this.user.id)
        .then(() => {
          this.dialog.show = false;
          this.deleteUserDialog.loading = false;
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.users.deleted"
          );

          this.$store.dispatch("organisations/deleteMember", {
            member: user,
            organisation: this.organisation
          });
        });
    },
    resetUser() {
      this.resetUserDialog.loading = true;
      let user = this.user;
      RepositoryFactory.get("users")
        .reset(user.id)
        .then(() => {
          this.resetUserDialog.show = false;
          this.resetUserDialog.loading = false;
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.users.reset"
          );

          this.$store.dispatch("organisations/deleteMember", {
            member: user,
            organisation: this.organisation
          });
        });
    },
    disableUser() {
      this.disableUserDialog.loading = true;
      let user = this.user;
      RepositoryFactory.get("users")
        .disable(user.id)
        .then(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.users.disabled"
          );
        })
        .catch(error => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            error.response.data.message
          );
        })
        .finally(() => {
          this.dialog.show = false;
          this.disableUserDialog.show = false;
          this.disableUserDialog.loading = false;
        });
    },
    enableUser() {
      this.disableUserDialog.loading = true;
      let user = this.user;
      RepositoryFactory.get("users")
        .enable(user.id)
        .then(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.users.enabled"
          );
        })
        .catch(error => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            error.response.data.message
          );
        })
        .finally(() => {
          this.dialog.show = false;
          this.enableUserDialog.show = false;
          this.enableUserDialog.loading = false;
        });
    },
    sendResetTwoFactorMail() {
      this.reset2faDialog.loading = true;
      let user = this.user;
      RepositoryFactory.get("users")
        .sendReset2faMail(user.id)
        .then(() => {
          this.reset2faDialog.show = false;
          this.reset2faDialog.loading = false;
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.users.2faReset"
          );
        });
    },
    loadBalance() {
      let username = this.user.licenses[0].meta.username ? this.user.licenses[0].meta.username : undefined;
      if (!username) return;
      if (!this.user.licenses.some(el => el.product === "otk")) return;
      RepositoryFactory.get("users").getBalance(username)
        .then(res => {
          console.log(res.data.balance);
          let otk = this.user.licenses.find(el => el.product === "otk");
          if (otk) {
            otk.meta.balance = res.data.balance;
          }
        });
    }
  },
  computed: {
    asAdmin() {
      return (
        this.$route.name === "admin.users" ||
        this.$route.name === "admin.organisations.details"
      );
    },
    ircLicense() {
      if (!this.user.licenses) {
        return;
      }
      return this.user.licenses.find(el => el.product === "irc");
    },
    otkLicense() {
      if (!this.user.licenses) {
        return;
      }
      return this.user.licenses.find(el => el.product === "otk");
    },
    darkwebLicense() {
      if (!this.user.licenses) {
        return;
      }
      return this.user.licenses.find(el => el.product === "darkweb");
    },
    canDelete() {
      if (!this.user) return false;
      if (!this.user.licenses) {
        return;
      }
      if (this.user.licenses.length > 0) return false;
      return this.$store.getters["auth/user"].id !== this.user.id;
    }
  },
  watch: {
    user: {
      handler(val) {
        if (val !== undefined) {
          this.dialog.show = true;
          this.loadBalance();
        }
      },
      deep: true
    },
    dialog: {
      handler(val) {
        if (!val.show) {
          this.$emit("dialogClose");
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.license-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  margin-bottom: 1rem;
}

.productNameSpan {
  font-weight: 500;
}

.v-application .teal {
  background-color: #28749c !important;
}

.toolbar-title-icon {
  margin-right: 1rem;
}

.dialog-row {
  margin: 0 !important;
}
</style>
